import { watcher } from "store";
import { isAnyOf } from "@reduxjs/toolkit";

import { accountServerThunks } from "store/account";

import { createScenario } from "./redux";

export const accountLoadScenario = createScenario<Account>(
  ({ getState, resolve }) => {
    const { isLoading, data } = getState().account;
    if (!isLoading) {
      return resolve(data);
    }

    watcher.startListening({
      effect: ({ payload }, { unsubscribe }) => {
        unsubscribe();
        resolve(payload);
      },
      matcher: isAnyOf(
        accountServerThunks.loadAccountServer.fulfilled,
        accountServerThunks.loadAccountServer.rejected,
      ),
    });
  },
);
